<template>
    <b-overlay :show="isLoading">
        <b-card class="white-bg">
            <template #header>
                <div class="row">
                    <div class="col-sm-12 text-right">
                        <div class="mt-2 font-italic">
                            Última actualización el <strong>{{ payment.updatedDate ? payment.updatedDate : payment.createdDate }}</strong> por <strong>{{ payment.updatedBy ? payment.updatedBy : payment.createdBy }}</strong>
                        </div>
                    </div>
                </div>
            </template>
            <b-row class="mt-2">
                <b-col class="col-md-6 col-lg-6">
                    <b-table-simple responsive bordered striped>
                        <b-tbody>
                            <b-tr>
                                <b-th style="width: 25%">Registrado</b-th>
                                <b-td>{{ payment.createdDate ? payment.createdDate : 'Sin registro' }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Fecha de pago</b-th>
                                <b-td>{{ payment.paymentDate ? payment.paymentDate : 'Sin registro' }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Registrado por</b-th>
                                <b-td>{{ payment.createdBy ? payment.createdBy : 'Sin registro' }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Tipo</b-th>
                                <b-td>{{ payment.type === 'INCOME' ? 'Ingreso' : 'Egreso' }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Método</b-th>
                                <b-td>
                                    <span v-if="payment.method === 'CREDIT_CARD'">Tarjeta de crédito</span>
                                    <span v-if="payment.method === 'DEBIT_CARD'">Tarjeta de débito</span>
                                    <span v-if="payment.method === 'CASH'">Efectivo</span>
                                    <span v-if="payment.method === 'CHECK'">Cheque</span>
                                    <span v-if="payment.method === 'TRANSFER'">Transferencia</span>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Importe</b-th>
                                <b-td>$ {{ formatCurrency(payment.amount) }} </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Abonado</b-th>
                                <b-td>$ {{ formatCurrency(payment.paidAmount) }} </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Saldo</b-th>
                                <b-td>$ {{ formatCurrency(payment.balance) }} </b-td>
                            </b-tr>
                            <template v-if="payment.status === 'CANCELLED'">
                                <b-tr>
                                    <b-th>Fecha de cancelación</b-th>
                                    <b-td>{{ payment.cancelledDate ? payment.cancelledDate : 'Sin registro' }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th>Cancelado por</b-th>
                                    <b-td>{{ payment.cancelledBy ? payment.cancelledBy : 'Sin registro' }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th>Motivo de la cancelación</b-th>
                                    <b-td>{{ payment.cancelNotes ? payment.cancelNotes : 'Sin registro' }}</b-td>
                                </b-tr>
                            </template>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <b-col class="col-md-6 col-lg-6 text-right">
                    <h1 class="font-weight-bolder">Pago de {{ payment.type === 'INCOME' ? 'ingreso' : 'egreso' }}</h1>
                    <h1 class="text-navy font-weight-bolder">{{ payment.folio }}</h1>
                    <h3 class="mb-3">
                        <b-badge variant="danger" class="button-sticky-right" v-if="payment.status === 'CANCELLED'">Cancelado</b-badge>
                        <b-badge variant="info" class="button-sticky-right" v-if="payment.status === 'TO_VERIFY'">Por verificar</b-badge>
                        <b-badge variant="primary" class="button-sticky-right" v-if="payment.status === 'VERIFIED'">Verificado</b-badge>
                    </h3>
                </b-col>
            </b-row>
            <hr>
            <b-row class="mt-4">
                <b-col>
                    <b-table-simple responsive bordered striped>
                        <b-thead>
                            <b-tr>
                                <b-th class="table-header-color" colspan="4">{{ payment.type === 'INCOME' ? 'Cliente' : 'Proveedor' }}</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th style="width: 50%" colspan="2">Datos</b-th>
                                <b-th style="width: 50%" colspan="2">Contacto</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-th style="width: 10%">Nombre</b-th>
                                <b-td>{{ payment.businessEntity.legalName ? payment.businessEntity.legalName : 'Sin registro'  }}</b-td>
                                <b-th style="width: 10%">Teléfono 1</b-th>
                                <b-td>{{ payment.businessEntity.phone1 ? payment.businessEntity.phone1 : 'Sin registro' }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>RFC</b-th>
                                <b-td>{{ payment.businessEntity.rfc ? payment.businessEntity.rfc : 'Sin registro' }}</b-td>
                                <b-th>Email 1</b-th>
                                <b-td>{{ payment.businessEntity.email1 ? payment.businessEntity.email1 : 'Sin registro' }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>Tipo</b-th>
                                <b-td>{{ payment.businessEntity.type === 'CUSTOMER' ? 'Cliente' : 'Proveedor' }}</b-td>
                                <b-th></b-th>
                                <b-td></b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <hr>
            <b-row class="mt-4">
                <b-col>
                    <b-table-simple responsive bordered striped>
                        <b-thead>
                            <b-tr>
                                <b-th class="table-header-color" colspan="11">Abonos a cuenta</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th class="text-center" v-if="hasPaymentItemsToVerify">
                                    <b-form-checkbox v-model="allcheckedToVerify" size="lg" class="mr-0" inline></b-form-checkbox>
                                </b-th>
                                <b-th class="text-center" style="width: 4%">#</b-th>
                                <b-th>Fecha</b-th>
                                <b-th>Folio de cuenta</b-th>
                                <b-th>Concepto</b-th>
                                <b-th>Registrado por</b-th>
                                <b-th>Observaciones</b-th>
                                <b-th>Transacción</b-th>
                                <b-th class="text-right">Abono</b-th>
                                <b-th>Estatus</b-th>
                                <b-th class="text-center">Acciones</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="(paymentItem, index) in payment.paymentItems" :key="paymentItem.guid" :class="{'text-danger': paymentItem.status === 'CANCELLED'}" :style="[paymentItem.status === 'CANCELLED' ? {'text-decoration': 'line-through'} : '']">
                                <b-td class="text-center" v-if="hasPaymentItemsToVerify">
                                    <template v-if="paymentItem.status === 'TO_VERIFY'">
                                        <b-form-checkbox v-model="paymentItem.checkedToVerify" size="lg" class="mr-0" inline></b-form-checkbox>
                                    </template>
                                </b-td>
                                <b-td class="text-center">{{ index + 1 }}</b-td>
                                <b-td>{{ formatDateTime(paymentItem.createdDate) }}</b-td>
                                <b-td>
                                    <router-link :to="{ name: 'AccountRpShow', params: { id: paymentItem.accountRpId }}" target="_blank">{{ paymentItem.folio }}</router-link>
                                </b-td>
                                <b-td>
                                    <router-link v-if="paymentItem.objectType === 'BusinessOrder'" target="_blank"
                                                 :to="{ name: 'BusinessOrderShow', params: { id: paymentItem.objectId }}">{{ paymentItem.orderFolio }}</router-link>
                                    <router-link v-else-if="paymentItem.objectType === 'ServiceOrder'" target="_blank"
                                                 :to="{ name: 'ServiceOrderShow', params: { id: paymentItem.objectId }}">{{ paymentItem.orderFolio }}</router-link>
                                    <router-link v-else-if="paymentItem.objectType === 'Expense'" target="_blank"
                                                 :to="{ name: 'ExpenseShow', params: { id: paymentItem.objectId }}">Gasto general {{ paymentItem.objectId }}</router-link>
                                </b-td>
                                <b-td>{{ paymentItem.createdBy }}</b-td>
                                <b-td>{{ paymentItem.cancelNotes }}</b-td>
                                <b-td>
                                    {{ paymentItem.transactionFolio }}
                                    <b-button size="sm" variant="outline-primary" v-b-tooltip.hover.top title="Imprimir"
                                              @click="transactionPage(paymentItem)" v-if="paymentItem.accountsInTransaction > 1">
                                        <font-awesome-icon icon="print"></font-awesome-icon>
                                    </b-button>
                                </b-td>
                                <b-td style="text-align: right">$ {{ formatCurrency(paymentItem.amount) }}</b-td>
                                <b-td>
                                    <span v-if="paymentItem.status === 'TO_VERIFY'">Por verificar</span>
                                    <span v-if="paymentItem.status === 'VERIFIED'">Verificado</span>
                                    <span v-if="paymentItem.status === 'CANCELLED'">Cancelado</span>
                                </b-td>
                                <b-td class="text-center">
                                    <b-button-group v-if="paymentItem.status !== 'CANCELLED' && !payment.businessOrderFolio" size="sm">
                                        <b-button size="sm" variant="outline-danger" v-b-tooltip.hover.top title="Cancelar" @click="cancelPaymentItem(paymentItem)">
                                            <font-awesome-icon icon="ban"></font-awesome-icon>
                                        </b-button>
                                    </b-button-group>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="payment.paymentItems.length === 0">
                                <b-td colspan="10" class="text-center font-weight-bold">Sin registros</b-td>
                            </b-tr>
                        </b-tbody>
                        <b-tfoot>
                            <b-tr>
                                <b-th class="text-right" :colspan="hasPaymentItemsToVerify ? 8 : 7">Total</b-th>
                                <b-th class="text-right">$ {{ formatCurrency(payment.paidAmount) }}</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th class="text-right" :colspan="hasPaymentItemsToVerify ? 8 : 7">Saldo</b-th>
                                <b-th class="text-right">$ {{ formatCurrency(payment.balance) }}</b-th>
                            </b-tr>
                        </b-tfoot>
                    </b-table-simple>
                    <b-row v-if="hasPaymentItemsToVerify">
                        <b-col cols="12">
                            <b-button class="button-sticky-right" @click="verifyPaymentItems">
                                <font-awesome-icon icon="check"></font-awesome-icon> Verificar abonos
                            </b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <template #footer>
                <div class="text-right">
                    <b-button variant="outline-secondary" :to="{ name: 'PaymentIndex' }"><font-awesome-icon icon="angle-left"></font-awesome-icon> Regresar</b-button>
                    <b-button class="ml-2" variant="secondary" @click="verifyPayment" v-if="payment.status === 'TO_VERIFY'"> Verificar</b-button>
                    <b-button class="ml-2" variant="danger" @click="cancelPayment" v-if="payment.status !== 'CANCELLED'"> Cancelar</b-button>
                    <b-button class="ml-2" variant="primary" @click="paymentPage"><font-awesome-icon icon="print"></font-awesome-icon> Imprimir</b-button>
                </div>
            </template>
        </b-card>
    </b-overlay>
</template>

<script>
import { paymentVerifyUrl, paymentCancelUrl, paymentUrl, paymentItemCancelUrl, paymentItemVerifyUrl, transactionPageUrl, paymentPageUrl } from '@routes';
import * as constants from '@constants';
import { BusinessEntity } from '../../models/businessEntity';
import { formatCurrency, formatDateTime } from '../../js/utilities';
import { Payment } from '../../models/payment';

export default {
    data() {
        return {
            payment: {
                id: 0,
                folio: '',
                businessOrderFolio: '',
                paymentDate: '',
                createdDate: '',
                createdBy: '',
                updatedDate: '',
                updatedBy: '',
                cancelledDate: '',
                cancelledBy: '',
                type: '',
                method: '',
                reference: '',
                notes: '',
                cancelNotes: '',
                businessEntity: {},
                amount: 0,
                paidAmount: 0,
                balance: 0,
                isApproved: '',
                paymentItems: [],
                accountsRp: []
            },
            breadcrumb: {
                title: 'Pagos',
                path: [
                    {
                        name: 'PaymentIndex',
                        text: 'Pagos'
                    }
                ]
            },
            swalTitle: 'Pagos',
            isLoading: false
        };
    },
    created() {
        this.payment.businessEntity = BusinessEntity.create();

        this.loadData();

        this.breadcrumb.path.push({
            name: this.$route.name,
            text: `Detalle`
        });

        this.$emit('update-breadcrumb', this.breadcrumb);
    },
    methods: {
        formatDateTime,
        formatCurrency,
        loadData() {
            this.isLoading = true;

            this.axios.get(paymentUrl(this.$route.params.id)).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.payment = response.data.payment;

                    this.accountRp = response.data.accountRp;

                    response.data.paymentItems.forEach(paymentItemData => {
                        let payment = Payment.create();

                        payment.id = paymentItemData.id;
                        payment.folio = paymentItemData.folio;
                        payment.accountRpId = paymentItemData.accountRpId;
                        payment.paymentId = paymentItemData.paymentId;
                        payment.amount = paymentItemData.amount;
                        payment.createdBy = paymentItemData.createdBy;
                        payment.createdDate = paymentItemData.createdDate;
                        payment.isApproved = paymentItemData.isApproved;
                        payment.status = paymentItemData.status;
                        payment.cancelNotes = paymentItemData.cancelNotes;
                        payment.transactionId = paymentItemData.transactionId;
                        payment.transactionFolio = paymentItemData.transactionFolio;
                        payment.accountsInTransaction = paymentItemData.accountsInTransaction;
                        payment.objectId = paymentItemData.objectId;
                        payment.objectType = paymentItemData.objectType;
                        payment.orderFolio = paymentItemData.orderFolio;

                        this.payment.paymentItems.push(payment);
                    });
                }

                this.$nextTick(() => this.isLoading = false);
            });
        },
        verifyPayment() {
            let title = '¿Estas seguro de verificar el pago?';

            this.$swal({
                title: title,
                icon: 'question'
            }).then(result => {
                if (result.value) {
                    this.isLoading = true;
                    this.axios.post(paymentVerifyUrl(), this.payment).then(response => {
                        if (response.data.code === constants.CODE_OK) {
                            this.$root.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'success'
                            });

                            this.loadData();
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(() => {
                        this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                            title: this.swalTitle,
                            variant: 'danger'
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        verifyPaymentItems() {
            if (this.paymentItemsToVerify.length === 0) {
                this.$bvToast.toast('Selecciona los abonos que deseas verificar', {
                    title: this.swalTitle,
                    variant: 'warning'
                });

                return;
            }

            let title = '¿Estas seguro de verificar los abonos seleccionados?';

            this.$swal({
                title: title,
                icon: 'question'
            }).then(result => {
                if (result.value) {
                    this.isLoading = true;
                    this.axios.post(paymentItemVerifyUrl(), this.paymentItemsToVerify).then(response => {
                        if (response.data.code === constants.CODE_OK) {
                            this.$root.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'success'
                            });

                            this.loadData();
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(() => {
                        this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                            title: this.swalTitle,
                            variant: 'danger'
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        cancelPayment() {
            let title = '¿Cancelar el pago?';

            this.$swal({
                title: title,
                icon: 'question',
                text: 'Razón de la cancelación: ',
                input: 'text',
                inputValidator: value => {
                    if (!value) {
                        return 'Es necesario ingresar una razón';
                    }
                }
            }).then(result => {
                if (result.value) {
                    this.isLoading = true;
                    this.payment.cancelNotes = result.value;

                    this.axios.post(paymentCancelUrl(), this.payment).then(response => {
                        if (response.data.code === constants.CODE_OK) {
                            this.$root.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'success'
                            });

                            this.loadData();
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(() => {
                        this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                            title: this.swalTitle,
                            variant: 'danger'
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        cancelPaymentItem(paymentItem) {
            let title = '¿Cancelar el abono?';

            this.$swal({
                title: title,
                icon: 'question',
                text: 'Razón de la cancelación: ',
                input: 'text',
                inputValidator: value => {
                    if (!value) {
                        return 'Es necesario ingresar una razón';
                    }
                }
            }).then(result => {
                if (result.value) {
                    this.isLoading = true;
                    paymentItem.cancelNotes = result.value;

                    this.axios.post(paymentItemCancelUrl(), paymentItem).then(response => {
                        if (response.data.code === constants.CODE_OK) {
                            this.$root.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'success'
                            });

                            this.loadData();
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.swalTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(() => {
                        this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                            title: this.swalTitle,
                            variant: 'danger'
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
                }
            });
        },
        transactionPage(paymentItem) {
            this.isLoading = true;
            this.axios.post(transactionPageUrl(), paymentItem).then(response => {
                let link = document.createElement('a');

                document.body.appendChild(link);

                link.setAttribute('type', 'application/pdf');
                link.href = 'data:application/pdf;base64,' + response.data.file;
                link.download = paymentItem.transactionFolio;
                link.click();

                document.body.removeChild(link);
            }).catch(() => {
                this.$bvToast.toast('Ocurrió un error al generar comprobante de transacción.', {
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        },
        paymentPage() {
            this.isLoading = true;
            this.axios.post(paymentPageUrl(), this.payment).then(response => {
                let link = document.createElement('a');

                document.body.appendChild(link);

                link.setAttribute('type', 'application/pdf');
                link.href = 'data:application/pdf;base64,' + response.data.file;
                link.download = this.payment.folio;
                link.click();

                document.body.removeChild(link);
            }).catch(() => {
                this.$bvToast.toast('Ocurrió un error al generar el comprobante de pago.', {
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        }
    },
    computed: {
        hasPaymentItemsToVerify() {
            return this.payment.paymentItems.some(item => item.status === 'TO_VERIFY');
        },
        paymentItemsToVerify() {
            return this.payment.paymentItems.filter(item => item.status === 'TO_VERIFY' && item.checkedToVerify);
        },
        allcheckedToVerify: {
            get() {
                return this.payment.paymentItems.every(item => item.checkedToVerify);
            },
            set(val) {
                for (let paymentItem of this.payment.paymentItems) {
                    if (paymentItem.status === 'TO_VERIFY') {
                        paymentItem.checkedToVerify = val;
                    }
                }
            }
        }
    }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: transform 0.5s;
}

.fade-enter,
.fade-leave-to {
    transform: translateX(-120%);
}

.fade-enter-to,
.fade-leave {
    transform: translateX(0);
}

.custom-control-label {
    color: #ffffff !important;
}
</style>